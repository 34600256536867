import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";
import {watch} from "vue";


const router = useRouter();

export const useMainStore = defineStore("main", {
  state: () => ({
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    organisations: [],
    projects: [],
    activeProject: null,
    activeOrganisation: null,
    activeOrganisationProjects: null,
    activeProjectService: null,


    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: [],
    products: [],
    updates: [],
  }),
  getters: {
    isDisplayingProject(){
      window.r = this.router;
      return !!this.router.currentRoute.value.params.projectConfigId;
    },
    isFocusModeOn(){
      return this.router.currentRoute.value.meta.isFocusModeOn
    },

    isDisplayingOrganisation(){
      window.r = this.router;
      return !!this.router.currentRoute.value.meta.organisationGroup
    },

    isDisplayingService(){
      window.r = this.router;
      return !!this.router.currentRoute.value.params.serviceConfigId
    },


    firstEnvironment(){

      if(!this.activeProject || !this.activeProject.environments.length){
        return null;
      }

      return this.activeProject.environments[0]

    },

    activeProjectEnvironments(){

      if(!this.activeProject){
        return null;
      }

      return this.activeProject.environments;

    }


  },


  actions: {

    findNextEnvironment(currentEnvironmentConfigId){

      let isPrevEnvCurrent = false;
      for(let env of this.activeProject.environments){
        if(isPrevEnvCurrent){
          return env;
        }

        if(env.configId === currentEnvironmentConfigId){
          isPrevEnvCurrent = true;
        }
      }

      return this.activeProject.environments[
        this.activeProject.environments.length - 1
        ];


    },


    findNextPromotionEnvironment(currentEnvironmentConfigId){

      let isPrevEnvCurrent = false;
      for(let env of this.activeProject.environments){
        if(isPrevEnvCurrent){
          return env;
        }

        if(env.configId === currentEnvironmentConfigId){
          isPrevEnvCurrent = true;
        }
      }

      return null


    },

    setActiveProjectService(projectService){
      this.activeProjectService = projectService
    },

    setActiveProject(project){
      this.activeProject = project;
      const org = this.getOrganisationById(project.organisationId);
      this.activeOrganisation = org;
    },

    addProject(project){

      this.projects = this.projects.filter((p) => p.id !== project.id)

      this.projects.push(project)
    },



    setActiveOrganisation(organisation, projects){
      this.activeOrganisation = organisation;
      this.activeOrganisationProjects = projects
    },

    getOrganisationById(id){
      const org =  this.organisations.find(o => o.id === id)
      return org;

    },


    getProjectEnvironmentByUuid(uuid){
      if(!this.activeProject){
        return null
      }

      return this.activeProjectEnvironments.find(e => e.id === uuid)
    },

    getProjectEnvironmentByConfigId(configId){
      if(!this.activeProject){
        return null
      }

      return this.activeProjectEnvironments.find(e => e.configId === configId)
    },

    getActiveProjectEnvironments(){
      return this.activeProjectEnvironments
    },

    getOrganisationByName(name){
      const result =  this.organisations.find(o => o.name === name)
      return result
    },

    getOrganisationByConfigId(configId){
      const org =  this.organisations.find(o => o.configId === configId)
      return org;
    },

    getServiceByName(projectName, name){

      let project = this.getProjectByName(projectName);
      let service = project.services.find(
        s => s.configId === name
      );

      if(service){
        return service;
      }


      return null;

    },

    getServiceByConfigId(projectConfigId, configId){

      let project = this.getProjectByConfigId(projectConfigId);
      let service = project.services.find(
        s => s.configId === configId
      );

      if(service){
        return service;
      }


      return null;

    },

    getProjectByName(name){
      const result =  this.projects.find(o => o.name === name)
      return result
    },

    getProjectByConfigId(configId){
      const result =  this.projects.find(o => o.configId === configId)
      return result
    },

    setUser(payload) {
      if (payload.name) {
        this.userName = payload.name;
      }
      if (payload.email) {
        this.userEmail = payload.email;
      }
      if (payload.avatar) {
        this.userAvatar = payload.avatar;
      }
    },

    fetch(sampleDataKey) {
      axios
        .get(`data-sources/${sampleDataKey}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            this[sampleDataKey] = r.data.data;
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    },
  },
});
